import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles/styles.css';
import Header from './components/Header';
import Footer from './components/Footer';
import MainContent from './components/MainContent';
import FighterIndex from './components/FighterIndex';
import Login from './components/Login';
import CreatePost from './components/CreatePost';
import BlogPost from './components/BlogPost';
import CalendarComponent from './components/CalendarComponent';  // Ensure correct relative path

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<MainContent />} exact />
          <Route path="/fighters" element={<FighterIndex />} />
          <Route path="/login" element={<Login />} />
          <Route path="/create-post" element={<CreatePost />} />
          <Route path="/posts" element={<BlogPost />} />
          <Route path="/calendar" element={<CalendarComponent />} /> {/* Calendar Route */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
