import React, { useEffect, useState } from 'react';
import '../styles/blogPost.css'; // Ensure this path is correct

const BlogPost = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch('https://api.loocreations.com/posts') // Ensure this is the correct API endpoint
      .then(response => response.json())
      .then(data => setPosts(data))
      .catch(error => console.error('Error fetching posts:', error));
  }, []);

  return (
    <div className="blog-post-container">
      {posts.map(post => (
        <div key={post._id} className="post-card">
          {post.image ? (
            <img src={post.image} alt={post.title} className="post-image" />
          ) : (
            <div className="post-image-placeholder">
              <span>No Image Available</span>
            </div>
          )}
          <div className="post-content">
            <h2 className="post-title">{post.title}</h2>
            <p className="post-excerpt">{post.content}</p> {/* Preserving formatting */}

            <p className="post-author">Written by: {post.alias || 'Unknown Author'}</p>
            <p className="post-date">
              Posted on: {new Date(post.createdAt).toLocaleDateString()}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogPost;
