import React, { useState } from 'react';
import '../styles/createPost.css';

function CreatePost() {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrorMessage('');

        try {
            const alias = localStorage.getItem('alias');
            const formData = new FormData();
            formData.append('title', title);
            formData.append('content', content);
            formData.append('alias', alias);
            if (image) {
                formData.append('image', image);
            }

            const response = await fetch('https://api.loocreations.com/posts', {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();

            if (response.ok) {
                setSuccessMessage('Post created successfully!');
                setTitle('');
                setContent('');
                setImage(null);
            } else {
                setErrorMessage(data.message || 'Failed to create post.');
            }

        } catch (error) {
            setErrorMessage('Error creating post. Please try again later.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="create-post-container">
            <form onSubmit={handleSubmit}>
                <label>Title:</label>
                <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Enter your post title"
                    required
                />
                <div className="character-counter">{title.length} / 100</div>

                <label>Content:</label>
                <textarea
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    placeholder="Write your content here..."
                    required
                />
                <div className="character-counter">{content.length} / 500</div>

                <label>Image:</label>
                <input
                    type="file"
                    onChange={handleImageChange}
                    accept="image/*"
                />

                {image && (
                    <div className="image-preview">
                        <img src={URL.createObjectURL(image)} alt="Preview" />
                    </div>
                )}

                <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting...' : 'Create Post'}
                </button>

                {successMessage && <div className="success-message">{successMessage}</div>}
                {errorMessage && <div className="error-message">{errorMessage}</div>}
            </form>

            {/* Live preview of the post */}
            <div className="post-preview">
                <h2>Live Preview</h2>
                <div className="post-card">
                    {image && (
                        <img src={URL.createObjectURL(image)} alt="Post Preview" className="post-image" />
                    )}
                    <div className="post-content">
                        <h2 className="post-title">{title || "Post Title"}</h2>
                        <p className="post-excerpt">{content || "Post content will appear here."}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreatePost;
