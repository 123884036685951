import React, { useEffect, useState } from 'react';
import '../styles/FighterIndex.css'; // Correct path to your CSS

const FighterIndex = () => {
    const [fighters, setFighters] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [weightClassFilter, setWeightClassFilter] = useState('All');
    const [currentPage, setCurrentPage] = useState(1);
    const fightersPerPage = 10;
    const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);

    useEffect(() => {
        // Debounce search input to avoid too many operations
        const timeoutId = setTimeout(() => {
            setDebouncedQuery(searchQuery);
        }, 300); // Adjust the debounce delay as necessary

        return () => clearTimeout(timeoutId);
    }, [searchQuery]);

    useEffect(() => {
        fetchFightersData();
    }, []);

    const fetchFightersData = async () => {
        try {
            const response = await fetch('https://api.loocreations.com/fighters');
            if (!response.ok) {
                throw new Error(`Error fetching fighters: ${response.statusText}`);
            }
            const data = await response.json();
            setFighters(data);
            setIsLoading(false);
        } catch (err) {
            setError(err.message);
            setIsLoading(false);
        }
    };

    const handleSearch = (e) => setSearchQuery(e.target.value);
    const handleWeightClassChange = (e) => setWeightClassFilter(e.target.value);

    const filteredFighters = fighters.filter(fighter => {
        const searchLower = debouncedQuery.toLowerCase();
        const matchesSearch = fighter.Name.toLowerCase().includes(searchLower);
        const matchesWeightClass = weightClassFilter === 'All' || fighter['Weight Class'] === weightClassFilter;
        return matchesSearch && matchesWeightClass;
    });

    const indexOfLastFighter = currentPage * fightersPerPage;
    const indexOfFirstFighter = indexOfLastFighter - fightersPerPage;
    const currentFighters = filteredFighters.slice(indexOfFirstFighter, indexOfLastFighter);

    const totalPages = Math.ceil(filteredFighters.length / fightersPerPage);

    const handlePrevPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
    const handleNextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));

    if (isLoading) return <div className="loading">Loading fighters...</div>;
    if (error) return <div className="error">Error: {error}</div>;

    return (
        <div className="fighter-index-container">
            <h1 className="fighter-title">Fighter Index</h1>

            <div className="filter-container">
                <input
                    type="text"
                    placeholder="Search fighters..."
                    value={searchQuery}
                    onChange={handleSearch}
                    className="search-bar"
                />

                <select value={weightClassFilter} onChange={handleWeightClassChange} className="filter-dropdown">
                    <option value="All">All Weight Classes</option>
                    <option value="Featherweight">Featherweight</option>
                    <option value="Lightweight">Lightweight</option>
                    <option value="Welterweight">Welterweight</option>
                    <option value="Middleweight">Middleweight</option>
                    <option value="Heavyweight">Heavyweight</option>
                </select>
            </div>

            <div className="fighter-grid">
                {currentFighters.map(fighter => (
                    <div key={fighter._id || fighter.Name} className="fighter-card">
                        <img
                            src={fighter['Image URL'] || 'default-image-url.jpg'}
                            alt={fighter.Name}
                            className="fighter-image"
                        />
                        <h3 className="fighter-name">{fighter.Name}</h3>
                        <p className="fighter-info">Nickname: {fighter.Nickname || 'Not Available'}</p>
                        <p className="fighter-info">Record: {fighter.Record || 'Not Available'}</p>
                        <p className="fighter-info">Weight Class: {fighter['Weight Class'] || 'Not Available'}</p>
                    </div>
                ))}
            </div>

            <div className="pagination">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>Previous</button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
            </div>
        </div>
    );
};

export default FighterIndex;
