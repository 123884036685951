import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import moment from 'moment'; // We'll use moment.js to handle date sorting
import '../styles/CalendarComponent.css';

const CalendarComponent = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    axios.get('https://api.loocreations.com/events')
      .then(response => {
        const eventList = response.data.map(event => ({
          id: event._id,
          title: event.event_name,
          date: event.date,
          location: event.location,
          main_event: event.main_event,
          fight_card: event.fight_card,
        })).sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort events by date
        setEvents(eventList);
      })
      .catch(error => console.error('Error fetching events:', error));
  }, []);

  const openModal = (event) => {
    setSelectedEvent(event);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="events-container">
      <h1>Upcoming UFC Events</h1>
      {/* Date-based Event List */}
      {events.map((event, index) => {
        // Format the date to show the month and day in a cleaner way
        const formattedDate = moment(event.date).format('MMMM Do, YYYY');
        return (
          <div key={index} className="event-section">
            <div className="date-header">
              <h2>{formattedDate}</h2>
            </div>
            <div className="event-card">
              <h3>{event.title}</h3>
              <p><strong>Location:</strong> {event.location}</p>
              <p><strong>Main Event:</strong> {event.main_event.fight}</p>

              {/* Fighter Thumbnail Preview */}
              <div className="fighters">
                {event.main_event.fighters.map((fighter, idx) => (
                  <div key={idx} className="fighter-thumbnail">
                    <img src={fighter.image} alt={fighter.name} className="fighter-image" />
                    <p>{fighter.name} ({fighter.record})</p>
                  </div>
                ))}
              </div>

              <button onClick={() => openModal(event)} className="view-details-btn">
                View Fight Card
              </button>
            </div>
          </div>
        );
      })}

      {/* Modal for event details */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Event Details"
        ariaHideApp={false}
      >
        {selectedEvent && (
          <div>
            <h2>{selectedEvent.title}</h2>
            <p><strong>Date:</strong> {moment(selectedEvent.date).format('MMMM Do, YYYY')}</p>
            <p><strong>Location:</strong> {selectedEvent.location}</p>

            {/* Main Event */}
            <h3>Main Event: {selectedEvent.main_event.fight}</h3>
            <div className="fighters">
              {selectedEvent.main_event.fighters.map((fighter, index) => (
                <div key={index} className="fighter-details">
                  <img src={fighter.image} alt={fighter.name} className="fighter-image" />
                  <p>{fighter.name} ({fighter.record})</p>
                </div>
              ))}
            </div>

            {/* Fight Card */}
            <h3>Fight Card:</h3>
            <ul>
              {selectedEvent.fight_card.map((fight, index) => (
                <li key={index}>
                  <h4>{fight.fight} ({fight.weight_class})</h4>
                  <div className="fighters">
                    {fight.fighters.map((fighter, idx) => (
                      <div key={idx} className="fighter-details">
                        <img src={fighter.image} alt={fighter.name} className="fighter-image" />
                        <p>{fighter.name} ({fighter.record})</p>
                      </div>
                    ))}
                  </div>
                </li>
              ))}
            </ul>

            <button onClick={closeModal} className="close-btn">Close</button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CalendarComponent;
