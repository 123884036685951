import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/header.css';


const Header = () => {
  return (
    <header className="header">
      <div className="logo-container">
       
      </div>
      <nav className="nav-links">
        <Link to="/" className="nav-link">Home</Link>
        <Link to="/fighters" className="nav-link">Fighter Index</Link>
      </nav>
    </header>
  );
};

export default Header;
