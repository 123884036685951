import React, { useState } from 'react';
import '../styles/login.css'; // Correct path to the CSS file

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch('https://api.loocreations.com/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password })
        })
        .then(response => response.json())
        .then(data => {
            console.log("Response data:", data); 
            if (data.message === "Login successful") {
                // Store the alias and other necessary user info in local storage
                localStorage.setItem('alias', data.alias); 
                localStorage.setItem('userId', data.userId); // if you want to store the userId as well

                alert('Login successful!');
                // Redirect to CreatePost page
                window.location.href = '/create-post';
            } else {
                alert('Login failed!');
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    return (
        <div className="login-container">
            <form onSubmit={handleSubmit}>
                <label>Username:</label>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                <label>Password:</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit">Login</button>
            </form>
        </div>
    );
}

export default Login;
